<template>
  <div
    class="main"
    v-loading="loading"
    element-loading-text="系统分析中，请等待..."
  >
    <heads></heads>
    <banners></banners>
    <!-- <islogin /> -->
    <div class="content">
      <div class="cons">
        <div class="cons-title" v-show="false">分词</div>
        <shuoming :smdata="smdata" :srcList="srcList"  v-show="false"></shuoming>
        <div class="cons-title-subtitle"  v-show="false">
          <p></p>
        </div>
        <div class="cons-form">
          <el-form :model="form" label-width="120px">
            <div class="custom-block">请输入一段文本</div>
            <el-form-item label="标题">
              <el-input
                v-model="form.bt"
                placeholder="请输入标题"
                @keydown.ctrl.enter="onSubmit()"
              />
            </el-form-item>
            <el-form-item label="输入文本">
              <el-input
                :rows="10"
                type="textarea"
                v-model="form.writerorg2"
                placeholder="请输入文本"
              />
            </el-form-item>
            <el-form-item label="hs">
              <el-input
                :rows="10"
                type="textarea"
                v-model="form.writerorg"
                placeholder="请输入文本"
                @keydown.ctrl.enter="onSubmit()"
              />
            </el-form-item>
            

            <div class="con-btn">
              <el-button type="primary" @click="onSubmit1()">立即提交1</el-button>
              <el-button type="primary" @click="onSubmit15()">立即提交1.5</el-button>
              <el-button type="primary" @click="onSubmit()">立即提交2</el-button>
            </div>
          </el-form>
        </div>
        <div class="tables"  v-show="false">
          <div class="custom-block">分词结果</div>
          <div class="xsfx">
            <div id="echartsyyy"></div>
          </div>
        </div>
        <div class="tables"  v-show="false">
          <div class="custom-block">演示结果</div>
          <el-table :data="tablelist" border  style="width: 100%">
            <el-table-column prop="key" label="词语" />
            <el-table-column prop="tf" sortable  label="tf" />
            <el-table-column prop="idf" sortable  label="原idf" />
            <el-table-column prop="tfidf" sortable  label="原tfidf" />
            <el-table-column prop="zlidf" sortable  label="专利idf" />
            <el-table-column prop="zltfidf" sortable  label="专利tfidf" />
            <el-table-column prop="tfidfzjkf" sortable  label="tfidfzjkf" />
            <el-table-column prop="zhpx" sortable  label="zhpx" />
          </el-table>
        </div>
        <div class="tables">
          <div class="custom-block">
            <div>输出结果</div>
            <el-button type="primary" @click="onSubmit()">换一个</el-button>
          </div>
          <el-input
              autosize
                type="textarea"
                v-model="resobj"
              />
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script setup>
import { post } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref, inject, getCurrentInstance } from "vue";
import "echarts-wordcloud";
import { useRouter } from "vue-router";
// 图片
let smdata = reactive([
  { url: "/img/shuoming/11-01.png", tips: "输入文本" },
  { url: "/img/shuoming/11-02.png", tips: "获取结果" },
]);
let srcList = reactive([
  "/img/shuoming/11-01.png",
  "/img/shuoming/11-02.png",
]);
const router = useRouter();
const { proxy } = getCurrentInstance();
// 词云
let $echarts = inject("echarts");
// ciyun
let stateciyun = reactive({
  option: {
    series: [
      {
        type: "wordCloud",
        // shape这个属性虽然可配置，但是在词的数量不太多的时候，效果不明显，它会趋向于画一个椭圆
        shape: "circle",
        // 这个功能没用过
        keepAspect: false,
        // maskImage这个是可以自定义背景图片的，词云会按照图片的形状排布，所以有形状限制的时候，最好用背景图来实现，而且，这个背景图一定要放base64的，不然词云画不出来
        // maskImage: '',
        // 下面就是位置的配置
        left: "center",
        top: "center",
        width: "90%",
        height: "90%",
        right: null,
        bottom: null,
        // 词的大小，最小12px，最大60px，可以在这个范围调整词的大小
        sizeRange: [12, 60],
        // 每个词旋转的角度范围
        rotationRange: [-90, 90],
        rotationStep: 45,
        // 词间距，数值越小，间距越小，这里间距太小的话，会出现大词把小词套住的情况，比如一个大的口字，中间会有比较大的空隙，这时候他会把一些很小的字放在口字里面，这样的话，鼠标就无法选中里面的那个小字
        gridSize: 8,
        // 允许词太大的时候，超出画布的范围
        drawOutOfBound: false,
        // 布局的时候是否有动画
        layoutAnimation: true,
        // 这是全局的文字样式，相对应的还可以对每个词设置字体样式
        textStyle: {
          fontFamily: "sans-serif",
          fontWeight: "bold",
          // 颜色可以用一个函数来返回字符串
          color: function () {
            // Random color
            return (
              "rgb(" +
              [
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160),
              ].join(",") +
              ")"
            );
          },
        },
        emphasis: {
          focus: "self",
          textStyle: {
            textShadowBlur: 10,
            textShadowColor: "#333",
          },
        },

        // 数据必须是一个数组，数组是对象，对象必须有name和value属性
        data: [],
      },
    ],
  },
});
let ciyundatas = reactive([]);
// loading
let loading = ref(false);
let tablelist = reactive([]);
let resobj=ref('')
let form = reactive({
  writerorg: `专利写作有一定的规范及标准的，请根据以下关键词并进行组合，围绕 ”@标题@“ 方面的内容，来拟写2个相关的专利名称，注意是分门别类列2个，并要求专利有一定的创新性和可行性，符合专利写作的规范及标准：并补充： 技术领域300个汉字， 背景技术300个汉字，应用场景300个汉字，以及专利中出现了哪3个以上发明点，哪4个以上创新点，并就发明实现的过程写10个以上步骤，关于技术方案方面的内容争取写300字以上。格式如下：  1、专利名称：\r\n 技术领域： \r\n背景技术：\r\n 应用场景： \r\n发明点： \r\n 创新点：\r\n 技术方案：\r\n\r\n,实现步骤:\r\n\r\n,请注意：专利名称以发明专利的风格来撰写,并用Markdown格式输出2个, 以下是关键词：\r\n`,
  writerorg2:'',
});

// 词云
const ciyun = () => {
  // 词云
  let mychart = proxy.$echarts.init(document.getElementById("echartsyyy"));
  // 这里是官方给出的一些基本的配置项，我做一些说明
  mychart.setOption(stateciyun.option);
};

const onSubmit1 = () => {
  loading.value = true;
  tablelist.length = 0;
  post("http://pb.yuanpingjia.cn:8055/api/Kw/GetSearchZl", {
    bt:form.bt,
    sentence: "",
    subhs: ""
  })
    .then((res) => {
      loading.value = false;
      console.log(res.data)
      form.writerorg2=res.data
    })
    .catch((err) => {
      loading.value = false;
    });
};

const onSubmit15 = () => {
  loading.value = true;
  tablelist.length = 0;
  post("http://pb.yuanpingjia.cn:8055/api/Kw/GetSearchZlByZqkj", {
    bt:form.bt,
    sentence: "",
    subhs: ""
  })
    .then((res) => {
      loading.value = false;
      console.log(res.data)
      form.writerorg2=res.data
    })
    .catch((err) => {
      loading.value = false;
    });
};

// 提交代码
const onSubmit = () => {
  loading.value = true;
  tablelist.length = 0;
  post("http://pb.yuanpingjia.cn:8055/api/Kw/GetZlCuts", {
    bt:form.bt,
    sentence:form.writerorg2,
    subhs:form.writerorg,
  })
    .then((res) => {
      loading.value = false;
      resobj.value=res.data.resdata
      // console.log(res);
      tablelist.push(...res.data.KeyList)
      ciyundatas.length = 0;
      ciyundatas.push(...res.data.KeyList);
      ciyundatas.forEach((e) => {
        e.name = e.key;
        e.value = e.zlidf;
      });
      stateciyun.option.series[0].data = ciyundatas;
      setTimeout(() => {
        ciyun();
      }, 100);
    })
    .catch((err) => {
      loading.value = false;
    });
};
</script>

<style scoped>
:deep(.el-form-item__label) {
  background: #f5f7fa;
  height: 42px;
  line-height: 42px;
  font-size: 18px;
}
:deep(.cons-form .el-input__inner) {
  height: 40px;
  font-size: 18px;
}
:deep(.cons-form .el-textarea__inner) {
  min-height: 100px !important;
  font-size: 18px;
}
:deep(.cons-form .el-button) {
  width: 180px;
  height: 50px;
  font-size: 18px;
}
.main {
  background: #fff;
}
.content {
  width: 1200px;
  height: auto;
  margin: auto;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0;
}
.cons-form {
  width: 100%;
}
.cons-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  padding: 0 0 10px 0;
}
.cons-subtitle {
  width: 100%;
  padding: 10px 30px;
  box-sizing: border-box;
  background: #f5f7fa;
  font-size: 14px;
  line-height: 20px;
  color: #999;
  margin: auto;
  margin-bottom: 30px;
}
.custom-block {
  padding: 10px 16px;
  background-color: #ecf5ff;
  border-radius: 4px;
  border-left: 5px solid #409eff;
  margin: 20px 0 20px 0;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.con-btn {
  text-align: center;
}
.cons-title-subtitle {
  color: #999;
  font-size: 14px;
}
.cons-title-subtitle p {
  margin: 0;
  padding: 0;
  text-align: center;
}
.qksx-list-con {
  font-size: 14px;
  padding: 20px 0 0 0;
}
.qksx-list-con:after {
  content: "";
  display: table;
  clear: both;
}
.xsqk-con {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.xsqk-list {
  cursor: pointer;
}
.xsqk-list-title {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
}
.xsfx,
#echartsyyy {
  width: 1200px;
  height: 600px;
}
</style>
